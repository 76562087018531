// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import { getUUID } from "@src/firebase/utils";

export const getProducts = createAsyncThunk(
  "appTour/getProducts",
  async (params) => {
    const response = await axios.get("/apps/ecommerce/products", { params });
    return { params, data: response.data };
  }
);

export const getTours = createAsyncThunk("appTour/getTours", async (params) => {
  //const response = await axios.get("/apps/ecommerce/products", { params })
  //console.log("params");
  return { params, data: params };
});

export const addToCart = createAsyncThunk(
  "appTour/addToCart",
  async (id, { dispatch, getState }) => {
    const response = await axios.post("/apps/ecommerce/cart", {
      productId: id,
    });
    await dispatch(getProducts(getState().ecommerce.params));
    return response.data;
  }
);

export const updateUsreTourStore = createAsyncThunk(
  "appTour/updateUsreTourStore",
  async (id /* { dispatch, getState } */) => {
    //    const response = await axios.post("/apps/ecommerce/cart", { productId: id })
    //   await dispatch(getProducts(getState().ecommerce.params))
    //console.log("id IN STORE", id);
    return id;
  }
);

export const addEditTour = createAsyncThunk(
  "appTour/addEditTour",
  async (id, { dispatch, getState }) => {
    //const response = await axios.post('/apps/ecommerce/cart', { productId: id })
    await dispatch(getProducts(getState().ecommerce.params));
    return response.data;
  }
);

export const getWishlistItems = createAsyncThunk(
  "appTour/getWishlistItems",
  async () => {
    const response = await axios.get("/apps/ecommerce/wishlist");
    return response.data;
  }
);

export const deleteWishlistItem = createAsyncThunk(
  "appTour/deleteWishlistItem",
  async (id, { dispatch }) => {
    const response = await axios.delete(`/apps/ecommerce/wishlist/${id}`);
    dispatch(getWishlistItems());
    return response.data;
  }
);

export const getCartItems = createAsyncThunk(
  "appTour/getCartItems",
  async () => {
    const response = await axios.get("/apps/ecommerce/cart");
    return response.data;
  }
);

export const getProduct = createAsyncThunk(
  "appTour/getProduct",
  async (slug) => {
    const response = await axios.get(`/apps/ecommerce/products/${slug}`);
    return response.data;
  }
);

export const addToWishlist = createAsyncThunk(
  "appTour/addToWishlist",
  async (id) => {
    await axios.post("/apps/ecommerce/wishlist", { productId: id });
    return id;
  }
);

export const deleteCartItem = createAsyncThunk(
  "appTour/deleteCartItem",
  async (id, { dispatch }) => {
    await axios.delete(`/apps/ecommerce/cart/${id}`);
    dispatch(getCartItems());
    return id;
  }
);

export const getStopIdx = (wantedStop, editTour) => {
  const stopIndex = editTour.stops.findIndex((object) => {
    return object.id === wantedStop;
  });
  return stopIndex;
};
const getMediaIdx = (wantedMedia, all_media) => {
  const mediaIndex = all_media.findIndex((object) => {
    return object.name === wantedMedia;
  });
  return mediaIndex;
};
export const getAllowedMedia = (stopID, editTour) => {
  const stopIdx = getStopIdx(stopID, editTour);
  if (stopIdx < 0) return [];
  const theStop = editTour.stops[stopIdx];
  const all_option_media = [
    "video",
    "audio",
    "text",
    "image",
    "question",
    "html",
    "coupon",
    "AIaudio",
  ];
  if (!theStop.type.includes("bigStop")) {
    if (theStop.all_media.length === 2) return [];
    /*  let fillter_arr = all_option_media.filter(type => {
      return (!theStop.all_media[0].type.includes(type))
    }) */
    theStop.all_media.map((media) => {
      let index = all_option_media.indexOf(media.type);
      if (index !== -1) all_option_media.splice(index, 1);
    });
    return all_option_media;
  } else {
    return all_option_media;
  }
};
export const appTourSlice = createSlice({
  name: "appTour",
  initialState: {
    cart: [],
    params: {},
    products: [],
    wishlist: [],
    totalProducts: 0,
    productDetail: {},
    allTours: [],
    editTour: {},
    selectedStop: {},
    newLocation: {},
    selectedMedia: {},
    mediaPercent: {},
  },
  reducers: {
    setWantedTour: (state, action) => {
      state.editTourID = action.payload.id;
      state.editTour = action.payload;
    },
    setStops: (state, action) => {
      const tmp_stops = [];
      action.payload.map((stop, idx) => {
        tmp_stops.push({ ...stop, order: idx });
      });
      state.editTour.stops = tmp_stops;
    },
    setSelectedStop: (state, action) => {
      state.selectedStop = action.payload;
    },
    setNewLocation: (state, action) => {
      state.newLocation = action.payload;
    },
    setBasicDataEditStop: (state, action) => {
      const {
        s_title = state.selectedStop?.s_title ?? "",
        slider_item_title = state.selectedStop?.slider_item_title ?? null,
        s_smallDesc = state.selectedStop?.s_smallDesc ?? null,
        type = state.selectedStop?.type ?? "bigStop",
        tags = state.selectedStop?.tags ?? null,
        hours_range = state.selectedStop?.hours_range ?? null,
        order = state.selectedStop?.order ?? 0,
        show_marker = state.selectedStop?.show_marker ?? true,
        include_path = state.selectedStop?.include_path ?? true,
        direction_text = state.selectedStop?.direction_text ?? null,
        icon = state.selectedStop?.icon ?? {
          value: "fa1",
          label: "Numbers",
          name: "none",
        },
        stop_location = state.selectedStop?.stop_location ?? {
          latitude: 35.689211761490746,
          longitude: 39.70077136936447,
        },
        mediaArray = state.selectedStop?.all_media ?? [],
        id = state.selectedStop?.id ?? null,
        direction_type, // = state.selectedStop?.direction_type ?? "foot",
      } = action.payload;

      let updatedStop = {
        id: id,
        s_title,
        slider_item_title,
        s_smallDesc,
        type,
        tags,
        hours_range,
        order,
        show_marker,
        include_path,
        direction_text,
        icon,
        stop_location: stop_location?.latitude
          ? stop_location
          : state.selectedStop?.stop_location,
        all_media: mediaArray,
        //direction_type,
      };

      // Conditionally add direction_type if it exists in the payload
      if (direction_type !== undefined) {
        updatedStop.direction_type = direction_type;
      }

      updatedStop = Object.entries(updatedStop).reduce(
        (a, [k, v]) => (v === undefined ? a : { ...a, [k]: v }),
        {}
      );
      const index = state.editTour.stops.findIndex(
        (stop) => stop.id === updatedStop.id
      );

      if (index !== -1) {
        state.editTour.stops[index] = {
          ...state.editTour.stops[index],
          ...updatedStop,
        };
        state.selectedStop = {
          ...state.editTour.stops[index],
          ...updatedStop,
        };
      } else {
        const newStopId = getUUID();
        updatedStop.id = newStopId;
        updatedStop.all_media = [];
        state.editTour.stops.push(updatedStop);
        state.selectedStop = updatedStop;
      }

      state.newLocation = {};
      state.editTour.lastUpdate = Date();

      /* state.selectedStop.s_title = action.payload.s_title;
      if (action.payload.s_smallDesc)
        state.selectedStop.s_smallDesc = action.payload.s_smallDesc;

      state.selectedStop.type = action.payload.type;
      state.selectedStop.tags = action.payload.tags;
      if (action.payload.hours_range) {
        state.selectedStop.hours_range = action.payload.hours_range;
      }

      state.selectedStop.order = action.payload.order;
      state.selectedStop.show_marker = action.payload.show_marker;
      state.selectedStop.include_path = action.payload.include_path;
      if (action.payload.direction_text) {
        state.selectedStop.direction_text = action.payload.direction_text;
      }
      if (action.payload.icon) state.selectedStop.icon = action.payload.icon;
      if (action.payload.stop_location.latitude) {
        state.selectedStop.stop_location = action.payload.stop_location;
        state.newLocation = {};
      }
      const index = state.editTour.stops.findIndex((object) => {
        return object.id === state.selectedStop.id;
      });
      if (index !== -1) {
        state.editTour.stops[index] = state.selectedStop;
        state.editTour.lastUpdate = Date();
        //console.log("1");
      } else {
        const newStopId = getUUID();
        state.selectedStop.id = newStopId;
        state.selectedStop.all_media = [];
        state.editTour.stops[state.editTour.stops.length] = state.selectedStop;
        state.editTour.lastUpdate = Date();
        //console.log("2");
      } */
      //return state;
    },
    setStopFormat: (state, action) => {
      const stopIndex = state.editTour.stops.findIndex((object) => {
        return object.id === action.payload.stopID;
      });
      state.editTour.stops[stopIndex].stopFormat = action.payload.stopFormat;
      state.editTour.lastUpdate = Date();
      //console.log("3");
    },
    setTourNotes: (state, action) => {
      state.editTour.notes = action.payload.notes;
      state.editTour.lastUpdate = Date();
      //console.log("4");
    },
    setTourDescription: (state, action) => {
      state.editTour.description = action.payload.description;
      state.editTour.lastUpdate = Date();
      //console.log("5");
    },
    setStopMedia: (state, action) => {
      const stopIdx = getStopIdx(action.payload.stopID, state.editTour);

      state.editTour.stops[stopIdx].all_media = action.payload.all_media;

      state.editTour.lastUpdate = Date();
      //console.log("6");
    },
    moveMediaToStop: (state, action) => {
      const srcIdx = getStopIdx(action.payload.srcStop, state.editTour);
      const dstIdx = getStopIdx(action.payload.dstStop, state.editTour);
      const srcMediaIdx = getMediaIdx(
        action.payload.mediaName,
        state.editTour.stops[srcIdx].all_media
      );
      const dstMediaIdx = getMediaIdx(
        action.payload.mediaName,
        state.editTour.stops[dstIdx].all_media
      );
    },
    setSelectedMedia: (state, action) => {
      state.selectedMedia = action.payload.wantedMedia;
    },
    addMediaToStop: (state, action) => {
      const stopIdx = getStopIdx(action.payload.stopID, state.editTour);

      const mediaIdx = getMediaIdx(
        action.payload.newMedia.name,
        state.editTour.stops[stopIdx].all_media
      );
      if (mediaIdx !== -1) {
        //state.editTour.stops[stopIdx].all_media[action.payload.newMedia.order] = action.payload.newMedia
        state.editTour.stops[stopIdx].all_media[mediaIdx] =
          action.payload.newMedia;
        state.editTour.lastUpdate = Date();
        state.selectedStop = state.editTour.stops[stopIdx];
        //console.log("7");
      } else {
        const addedMedia = {
          ...action.payload.newMedia,
          order: state.editTour.stops[stopIdx].all_media.length,
        };
        const newMediaArr = [
          ...state.editTour.stops[stopIdx].all_media,
          addedMedia,
        ];
        state.editTour.stops[stopIdx].all_media = newMediaArr;
        state.editTour.lastUpdate = Date();
        state.selectedStop = state.editTour.stops[stopIdx];
        //console.log("8");
      }
    },
    addLocPicToStop: (state, action) => {
      const stopIdx = getStopIdx(action.payload.stopID, state.editTour);
      state.editTour.stops[stopIdx].loc_pics = [action.payload.newLocPic];
      state.editTour.lastUpdate = Date();
      state.selectedStop = state.editTour.stops[stopIdx];
      //console.log("9");
    },
    addCouponToStop: (state, action) => {
      const stopIdx = getStopIdx(action.payload.stopID, state.editTour);
      state.editTour.stops[stopIdx].coupon = [action.payload.coupon];
      state.editTour.lastUpdate = Date();
      state.selectedStop = state.editTour.stops[stopIdx];
      //console.log("9");
    },
    removeLocPic: (state, action) => {
      const stopIdx = getStopIdx(action.payload.stopID, state.editTour);
      state.editTour.stops[stopIdx].loc_pics = [];
      state.editTour.lastUpdate = Date();
      state.selectedStop = state.editTour.stops[stopIdx];
      //console.log("10");
    },
    updatePercent: (state, action) => {
      const new_arr = {
        ...state.mediaPercent,
        [action.payload.mediaID]: action.payload.percent,
      };
      state.mediaPercent = new_arr;
    },
    removeMedia: (state, action) => {
      const stopIdx = getStopIdx(action.payload.stopID, state.editTour);
      const mediaIdx = getMediaIdx(
        action.payload.selectedMedia.name,
        state.editTour.stops[stopIdx].all_media
      );

      if (mediaIdx !== -1) {
        const newMediaArr = state.editTour.stops[stopIdx].all_media;
        newMediaArr.splice(mediaIdx, 1);
        state.editTour.stops[stopIdx].all_media = newMediaArr.map(
          (media, index) => {
            return { ...media, order: index };
          }
        );
        state.editTour.lastUpdate = Date();
        state.selectedStop = state.editTour.stops[stopIdx];
        //console.log("11");
      }
    },
    setEditTour: (state, action) => {
      state.editTour = action.payload;
      state.editTour.lastUpdate = Date();
    },
    addMediaTour: (state, action) => {
      const newMedia = {
        name: action.payload.name,
        type: action.payload.type,
        url: action.payload.url,
        order: state.editTour.all_media.length,
      };
      state.editTour.all_media.push(newMedia);
      state.editTour.lastUpdate = Date();
      //console.log("12");
    },
    removeTourPicture: (state, action) => {
      const newMediaArr = state.editTour.all_media;
      newMediaArr.splice(action.payload.idx, 1);
      state.editTour.all_media = newMediaArr.map((media, index) => {
        return { ...media, order: index };
      });
      state.editTour.lastUpdate = Date();
      //console.log("13");
    },
    removeProfilePicture: (state, action) => {
      state.editTour.tour_image_obj = {};
      state.editTour.tour_image = "";
      state.editTour.lastUpdate = Date();
      //console.log("14");
    },
    addProfilePicture: (state, action) => {
      state.editTour.tour_image_obj = action.payload;
      state.editTour.tour_image = action.payload.url;
      state.editTour.lastUpdate = Date();
      //console.log("15");
    },
    deleteTourTrailer: (state, action) => {
      const trailerIndex = state.editTour.all_media.findIndex((object) => {
        return object.type.includes("video");
      });
      if (trailerIndex !== -1) {
        const newMediaArr = state.editTour.all_media;
        newMediaArr.splice(trailerIndex, 1);
        state.editTour.all_media = newMediaArr.map((media, index) => {
          return { ...media, order: index };
        });
        state.editTour.lastUpdate = Date();
        //console.log("16");
      }
    },
    addTourTrailer: (state, action) => {
      const newAllMedia = [
        ...state.editTour.all_media,
        { ...action.payload, order: state.editTour.all_media.length },
      ];
      state.editTour.all_media = newAllMedia;
      state.editTour.lastUpdate = Date();
      //console.log("17");
    },
    addStartingVideo: (state, action) => {
      state.editTour.starting_name = action.payload.starting_name;
      state.editTour.starting_video = action.payload.starting_url;
      state.editTour.lastUpdate = Date();
      //console.log("18");
      return state;
    },
    deleteStartingVideo: (state, action) => {
      state.editTour.starting_video = null;
      state.editTour.starting_name = null;
      state.editTour.lastUpdate = Date();
      //console.log("19");
    },
    deleteStopFromTour: (state, action) => {
      const stopIdx = getStopIdx(action.payload, state.editTour);

      if (stopIdx !== -1) {
        const newStopArr = state.editTour.stops;
        newStopArr.splice(stopIdx, 1);

        state.editTour.stops = newStopArr.map((media, index) => {
          return { ...media, order: index };
        });
        state.editTour.lastUpdate = Date();
      }
      //console.log("20");
    },
    setGuidePic: (state, action) => {
      //console.log("21");
      state.editTour.tour_guide.profile_image = action.payload;
      state.editTour.lastUpdate = Date();
      //console.log("22");
    },
    setLastUpdate: (state, action) => {
      state.editTour.lastUpdate = Date();
      //console.log("setLastUpdate");
    },
    setDistance: (state, action) => {
      state.editTour.distance = action.payload;
      //state.editTour.lastUpdate = Date();
      //console.log("23");
    },
    setTourConfig: (state, action) => {
      state.editTour.config = action.payload;
      state.editTour.lastUpdate = Date();
      //console.log("24");
    },
    setTourTitle: (state, action) => {
      state.editTour.title = action.payload;
      state.editTour.lastUpdate = Date();
      //console.log("25");
    },
    setStopType: (state, action) => {
      const stopIdx = getStopIdx(action.payload.stopID, state.editTour);

      if (stopIdx !== -1) {
        state.editTour.stops[stopIdx].type = action.payload.stopType;
        state.editTour.lastUpdate = Date();
      }
    },
    setTourAIQues: (state, action) => {
      state.editTour.AIqus = action.payload;
      state.editTour.lastUpdate = Date();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.params = action.payload.params;
        state.products = action.payload.data.products;
        state.totalProducts = action.payload.data.total;
      })
      .addCase(getTours.fulfilled, (state, action) => {
        state.params = action.payload.params;
        state.tours = action.payload.data.tours;
        state.totalTours = action.payload.data.total;
      })
      .addCase(getWishlistItems.fulfilled, (state, action) => {
        state.wishlist = action.payload.products;
      })
      .addCase(getCartItems.fulfilled, (state, action) => {
        state.cart = action.payload.products;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productDetail = action.payload.product;
      });
  },
});

export const {
  setWantedTour,
  setStops,
  setSelectedStop,
  setNewLocation,
  setBasicDataEditStop,
  setStopFormat,
  setStopMedia,
  moveMediaToStop,
  setSelectedMedia,
  updatePercent,
  addMediaToStop,
  addLocPicToStop,
  addCouponToStop,
  removeMedia,
  setEditTour,
  addMediaTour,
  removeTourPicture,
  removeProfilePicture,
  addProfilePicture,
  deleteTourTrailer,
  addTourTrailer,
  deleteStopFromTour,
  removeLocPic,
  addStartingVideo,
  deleteStartingVideo,
  setTourNotes,
  setTourDescription,
  setGuidePic,
  setDistance,
  setTourConfig,
  setTourTitle,
  setStopType,
  setLastUpdate,
  setStopDescription,
  setTourAIQues,
} = appTourSlice.actions;

export default appTourSlice.reducer;
